import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import { FaLocationPin } from 'react-icons/fa6';
import { BsArrowRight, BsPlus } from 'react-icons/bs';
import { faker } from '@faker-js/faker';
import FsLightbox from 'fslightbox-react';
import { PiPhoneDuotone, PiMapPinDuotone, PiEnvelopeSimpleDuotone } from 'react-icons/pi';
import { FaInstagram } from 'react-icons/fa';
import { BiLogoFacebook } from 'react-icons/bi';

import ContactForm from 'components/ContactForm.js';

import mapConfig from 'config/mapConfig.js';

export default function MainPage(props) {
    const [content, setContent] = useState(null);

    useEffect(() => {
        // if (slug) {
        const url = global.endpoint + 'wp-json/wp/v2/pages?slug=' + 'fooldal' + '&_embed';
        axios.get(url, {}).then(function (response) {
            if (response.data)
                setContent(response.data[0]);
        }).catch(function (error) { console.log(error); });
        // }
    }, []);

    return (
        <div className={'w-full flex flex-col  ' + (props.className || '')}>

            {content && <Slider data={content && content.acf.photo_gallery.slideshow[1]} />}

            <About />

            <Welcome />

            <ContactRow dark />

            {(content && content.acf.photo_gallery.galeria[0].length > 0) && <GalleryRow data={content.acf.photo_gallery.galeria[0]} />}

            <MapRow />

        </div>
    );
}

function Slider(props) {
    const [activeIndex, setActiveIndex] = useState(0);

    const transitionDuration = 7000;

    let getNext = () => { return activeIndex + 1 >= props.data.length ? 0 : activeIndex + 1; }
    // let getPrev = () => { return activeIndex - 1 < 0 ? list.length - 1 : activeIndex - 1; }

    useEffect(() => {
        if (props.data != null)
            setActiveIndex(0);
    }, [props.data])

    useEffect(() => {
        let timer = setTimeout(() => {
            const next = getNext();
            setActiveIndex(next);
        }, transitionDuration);
        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeIndex])

    return (
        <div className='relative w-full min-h-screen flex flex-col items-center justify-center overflow-hidden -mb-16'>

            {props.data && props.data.map((item, index) => (<Slide key={item.id} data={item} active={activeIndex === index} />))}

            <div className='absolute w-full h-full top-0 left-0 bg-gradient-to-br from-stone-900/70 to-stone-700/40 mix-blend-multiply' />

            <div className='w-full max-w-boxed flex flex-col items-center z-10 text-center px-site'>
                <div className='text-white font-bold text-5xl uppercase' dangerouslySetInnerHTML={{ __html: 'SILOÁM REFORMÁTUS MISSZIÓI OTTHON' }} />
                <div className='text-white font-light text-3xl mt-1' dangerouslySetInnerHTML={{ __html: 'Balatonfüred' }} />
                <div className='max-w-2xl text-white text-sm my-8' dangerouslySetInnerHTML={{ __html: 'Szálláshelyünk a balatonfüredi strandtól 10 perces, a hajókikötőtől pedig kb 5 perces sétára található. Az épület több mint 100 éves. Teljes felújításon esett át az elmúlt években, így egy teljesen megújult impozáns környezet várja az ide érkező vendégeinket.' }} />
                <Link to={'/szallas'} className='button-light-hover'>{'A szállásról'}</Link>
            </div>

        </div>
    );
}

function Slide(props) {
    return (
        <div className={'absolute top-0 left-0 w-full h-full flex items-center justify-center bg-cover bg-no-repeat bg-center px-site transition duration-1000 ' + (props.active ? 'opacity-100 scale-100' : 'scale-105 opacity-0')} style={{ backgroundImage: 'url(' + props.data.full_image_url + ')' }}>

        </div>
    );
}

function About(props) {
    return (
        <div className='w-full flex flex-col items-center'>

            <div className='w-full flex flex-col lg:flex-row lg:items-stretch z-10'>

                <div className='w-full lg:w-1/2 gap-5 flex justify-end bg-stone-100 py-12 pl-site'>
                    <div className='w-full lg:max-w-boxed-1/2 flex flex-col gap-5 pr-site'>
                        <div className='flex-1 flex flex-col gap-5'>
                            <div className='font-semibold uppercase text-2xl'>A ház története</div>
                            <div className='text-sm leading-loose text-pretty text-neutral-500'>A területet, amelyen a helyi védettséget élvező épület áll, 1894-ben parcellázták fel nyaralótelkeknek, miután a bencés rend a Veszprémi káptalantól megvásárolta. 1896-ban már Tornay nyaralóként szerepel a vendégnévsorban. Valószínüleg ők építik a telken álló másik épületet, a későbbi Dániel nyaralót is, mert az 1900-ban készült térképen már közös kertben állnak.</div>
                        </div>
                        <Link to='/multunk' className='text-primary transition-colors hover:text-stone-700 uppercase font-semibold flex items-center gap-3 group'><BsArrowRight className='text-xl' /><div>teljes történet</div></Link>
                    </div>
                </div>

                <div className='w-full lg:w-1/2 gap-5 flex justify-start bg-primary text-white py-12 pr-site'>
                    <div className='w-full lg:max-w-boxed-1/2 flex flex-col gap-5 pl-site'>
                        <div className='flex-1 flex flex-col gap-5'>
                            <div className='font-semibold uppercase text-2xl'>A Siloám Református Missziói Otthon küldetésnyilatkozata</div>
                            <div className='text-sm leading-loose text-pretty text-white/50'>
                                A Siloám szó jelentése: küldött.
                                Hogyan lehet egy épület küldött - merülhet fel jogosan a kérdés?
                                Elsősorban úgy, hogy a benne megrendezésre kerülő alkalmak alkalmasak az Istenhez való közeledésre, az Istennel való kapcsolat elmélyítésére.
                            </div>
                        </div>
                        <Link to='/kuldetesunk' className='text-white transition hover:opacity-75 uppercase font-semibold flex items-center gap-3 group'><BsArrowRight className='text-xl' /><div>küldetésnyilatkozat</div></Link>
                    </div>
                </div>

            </div>

        </div>
    );
}

function Welcome(props) {
    return (
        <div className='flex flex-col items-center py-16 px-site'>

            <div className='w-full max-w-3xl flex flex-col gap-12 items-center text-center'>

                <div className='text-3xl font-bold uppercase'>A szállásról</div>

                <div className='text-neutral-500 text-sm leading-relaxed'>Szálláshelyünk a balatonfüredi strandtól kb 10 perces, a hajókikötőtől pedig kb 5 perces sétára található. Az épület több mint 100 éves. Teljes felújításon esett át az elmúlt években, így egy teljesen megújult impozáns környezet várja az ide érkező vendégeinket.<br /><br />A szálláshelyen 2, 3 és 4 ágyas (fürdőszobás) szobák várják a pihenésre, kikapcsolódásra, lelki feltöltődésre érkezőket. Az épületen belül található konferencia terem, könyvtár és a fedett terasz is. A recepción büfé működik, ahol kávét és frissítőket vásárolhatnak vendégeink.</div>

            </div>

        </div>
    );
}

export function GalleryRow(props) {
    const [toggler, setToggler] = useState(false);

    const getSourcesArray = () => {
        let array = [];
        for (let i = 0; i < props.data.length; i++) array.push(props.data[i].full_image_url);
        return array;
    };

    return (
        <div className='w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 bg-stone-100'>

            <FsLightbox toggler={toggler} sources={getSourcesArray()} types={[...new Array(getSourcesArray().length).fill('image')]} />

            {props.data.map((item) => (<GalleryItem key={item.id} data={item} onOpen={() => setToggler(!toggler)} />))}

        </div>
    );
}

export function GalleryItem(props) {
    return (
        <button className='relative flex items-center justify-center aspect-video bg-cover group overflow-hidden bg-stone-100' style={{ backgroundImage: 'url(' + props.data.full_image_url + ')' }} onClick={() => props.onOpen()}>

            <div className='absolute top-0 left-0 w-full h-full bg-gradient-to-br from-black/60 to-black/30 opacity-0 group-hover:opacity-100 transition' />

            <div className='flex items-center justify-center size-12 bg-primary rounded-full scale-50 group-hover:scale-100 opacity-0 group-hover:opacity-100 transition z-10'><BsPlus className='text-white text-xl' /></div>

        </button>
    );
}

export function ContactRow(props) {
    return (
        <div className={'w-full flex flex-col items-center py-16 px-site ' + (props.dark ? 'bg-stone-700' : '')}>

            <div className='w-full max-w-boxed flex flex-col lg:flex-row'>

                <ContactForm dark={props.dark} className={'w-full ' + (props.dark ? 'lg:w-[720px] lg:pr-16' : 'lg:flex-1 lg:pl-16 order-2')} />

                <div className={'flex flex-col justify-between gap-6 lg:border-l border-white/10 ' + (props.dark ? 'lg:pl-16 pt-16 lg:pt-0' : 'lg:pr-16 order-1 pb-16 lg:pb-0')}>

                    <div className={'flex flex-col items-center text-center lg:items-start lg:text-left gap-4 ' + (props.dark ? 'text-white' : '')}>
                        <div>
                            <div className='font-semibold'>Siloám Református Missziói Otthon</div>
                            <div className='text-sm'>Balatonfüred</div>
                        </div>
                        <div className='flex flex-col gap-0.5 text-xs'>
                            <div>Üzemeltető: SILOÁM HÁZ Kft.</div>
                            <div>Székhely: 8230 Balatonfüred, Honvéd utca 6-8.</div>
                            <div>Cégjegyzékszám: 19 09 524424</div>
                            <div>Adószám: 32528354-2-19</div>
                        </div>
                    </div>

                    {/* <div className='flex gap-2'>
                        <BiLogoFacebook />
                        <FaInstagram />
                    </div> */}

                    <div className={'flex flex-col md:flex-row lg:flex-col items-center lg:items-start gap-6 ' + (props.dark ? 'text-white' : '')}>

                        <ContactItem icon={<PiEnvelopeSimpleDuotone />} title='E-mail címünk' list={[{ label: 'informacio@siloambalatonfured.hu', href: 'mailto:informacio@siloambalatonfured.hu' }]} />
                        <ContactItem icon={<PiPhoneDuotone />} title='Telefonszámunk' list={[{ title: 'Oláhné Gerzsenyi Zsuzsanna', label: '+36 20 945 4149', href: 'tel:+36209454149' }, { title: 'Szabó Gábor', label: '+36 30 538 0865', href: 'tel:+36305380865' }]} />
                        <ContactItem icon={<PiMapPinDuotone />} title='Címünk' list={[{ label: '8230 Balatonfüred, Honvéd u. 6', href: 'https://maps.app.goo.gl/DWFXmuppmDwrJAn27' }]} />
                        {/* <ContactItem icon={<PiMapPinDuotone />} title='Közösségi média' list={[{ label: 'Facebook',title: 'Oláhné Gerzsenyi Zsuzsanna', href: 'https://maps.app.goo.gl/DWFXmuppmDwrJAn27' }]} /> */}


                    </div>

                </div>

            </div>

        </div>
    );
}

function ContactItem(props) {
    return (
        <div className='sm:flex-1 lg:flex-none flex flex-col items-center lg:items-start gap-2'>

            <div className='flex items-center gap-2'>
                <div className=''>{props.icon}</div>
                <div className='font-semibold text-sm uppercase'>{props.title}</div>
            </div>

            {props.list && props.list.map((item) => (<div key={item.label} className='flex flex-col items-center lg:items-start text-center lg:text-left'>

                {item.title && <div className='text-xs opacity-75'>{item.title}</div>}
                <a href={item.href} className='text-sm sm:text-xs lg:text-sm transition hover:text-primary'>{item.label}</a>

            </div>))}

            {/* <a href={props.href} className='text-sm sm:text-xs lg:text-sm transition hover:text-primary'>{props.label}</a> */}

        </div>
    );
}

export function MapRow(props) {
    return (
        <div className={'w-full h-[520px] bg-stone-100 ' + (props.className || '')}>

            <GoogleMapReact
                key='map'
                bootstrapURLKeys={{ key: 'AIzaSyD1luTKqk_RQjga3FZ2vTeFJhms3nDrjSg', language: 'hu-HU', region: 'hu', }}
                options={map => ({
                    disableDefaultUI: true,
                    styles: mapConfig.styles,
                })}
                defaultCenter={{ lat: 46.953732, lng: 17.890171 }}
                yesIWantToUseGoogleMapApiInternals
                defaultZoom={16}
                disableDefaultUI={true}>

                {/* <div className='w-[10000%] h-[10000%] -translate-x-1/2 -translate-y-1/2 bg-primary-dark/60'></div> */}

                <div className='relative w-0 h-0 flex flex-col items-center z-10' lat={46.953732} lng={17.890171}>
                    <FaLocationPin className='absolute bottom-0 text-primary text-5xl z-10' />
                    <div className='absolute flex flex-col gap-0.5 bottom-10 bg-white/95 p-4 text-center shadow-xl whitespace-nowrap text-xs'>
                        <div className='font-bold'>Siloám Református Missziói Otthon - Balatonfüred</div>
                        <div>8230 Balatonfüred, Honvéd u. 6</div>
                    </div>
                </div>

            </GoogleMapReact>

        </div>
    );
}